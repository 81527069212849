/**
 * This is the denominator of the current app version.
 * This constant has a direct impact on whether or not service worker updates will be read by the browser
 *
 * !!! Remember to always update this when a new version is built
 */

// Export version as a constant
export const version = '1.1.656';

// Add cache-busting timestamp that changes on each import
export const versionTimestamp = Date.now();

// Compare semantic versions
export const isNewerVersion = (oldVer: string, newVer: string): boolean => {
    const oldParts = oldVer.split('.').map(Number);
    const newParts = newVer.split('.').map(Number);
    for (let i = 0; i < 3; i++) {
        if (newParts[i] > oldParts[i]) return true;
        if (newParts[i] < oldParts[i]) return false;
    }
    return false;
};

// Type-safe getter for version
export const getVersion = (): string => version;
