import * as Sentry from '@sentry/react';
import Api from '_services/Api';
import { AxiosResponse, AxiosError } from 'axios';

import ApiWithFileUpload from '_services/Api/WithFileUpload';
import CookieService from '_services/Cookies';
import LocalStorageCoupon from '_services/LocalStorage/Coupon/LocalStorageCoupon';
import { UserShape, UserUpdateDataShape } from '_types/User';
import {
    CommonRegistrationData,
    DeleteUserData,
    FacebookRegistrationData,
    ForgotPasswordData,
    FormRegistrationData,
    GoogleRegistrationData,
    LoginData,
    ResetPasswordData,
} from './type';

type couponAbleDataShape = {
    coupon_code?: string;
};

/**
 * Checks whether there is a coupon code in the LocalStorage,
 * and appends it to data if it exists
 *
 * @param {couponAbleDataShape} data
 * @returns {couponAbleDataShape}
 */
const checkAddCoupon = (data: couponAbleDataShape): couponAbleDataShape => {
    const couponCode = LocalStorageCoupon.get();
    if (couponCode) {
        /**
         * If the user has a couponCode in the LocalStorage,
         * then we add that to the registration api post,
         * because that will save the used coupon relationship in the API
         */
        data.coupon_code = couponCode;
    }
    return data;
};
/**
 *
 * @param data
 * @returns
 */
const addExtraRegistrationData = (
    data: CommonRegistrationData,
): CommonRegistrationData => {
    const invitedByCode = CookieService.getInvitedBy();
    if (invitedByCode) {
        /**
         * If the cookie shows that this user has been invited by somebody,
         * then we add that code to the registration api post,
         * because that will store the invited/invitor relationship in the API
         */
        data.invited_by = invitedByCode;
    }

    data = checkAddCoupon(data) as CommonRegistrationData;

    return data;
};
/**
 * Helper object used for doing user-related API communication with the Backend
 */
const UserApi = {
    /**
     * Registers a user with the data given in the registration form
     *
     * @param {FormRegistrationData} data
     * @returns {Promise<AxiosResponse>}
     */
    async register(
        data: FormRegistrationData,
    ): Promise<AxiosResponse<UserShape>> {
        data = addExtraRegistrationData(data) as FormRegistrationData;

        return Api.post('register', data);
    },

    /**
     * Registers user with data from Facebook
     * @param {FacebookRegistrationData} data
     * @returns {Promise<AxiosResponse>}
     */
    async registerWithFacebook(
        data: FacebookRegistrationData,
    ): Promise<AxiosResponse<UserShape>> {
        data = addExtraRegistrationData(data) as FacebookRegistrationData;

        return Api.post('register/facebook', data);
    },

    /**
     * Registers user with data from Google
     * @param {GoogleRegistrationData} data
     * @returns {Promise<AxiosResponse>}
     */
    async registerWithGoogle(
        data: GoogleRegistrationData,
    ): Promise<AxiosResponse<UserShape>> {
        data = addExtraRegistrationData(data) as GoogleRegistrationData;

        return Api.post('register/google', data);
    },

    /**
     * Tries to login user with the given data
     * @param {LoginData} data
     * @returns Promise<AxiosResponse>
     */
    async login(data: LoginData): Promise<AxiosResponse<{ user: UserShape }>> {
        data = checkAddCoupon(data) as LoginData;

        return Api.post('login', data);
    },

    /**
     * Tries to login user with the given facebook id
     * @param {LoginData} data
     * @returns Promise<AxiosResponse>
     */
    async loginWithFacebook(
        facebookId: string,
    ): Promise<AxiosResponse<UserShape>> {
        type FacebookLoginDataShape = {
            facebook_id: string;
            coupon_code?: string;
        };
        let data: FacebookLoginDataShape = {
            facebook_id: facebookId,
        };
        data = checkAddCoupon(data) as FacebookLoginDataShape;

        return Api.post('login/facebook', data);
    },

    /**
     * Tries to login user with the given google id
     * @param {LoginData} data
     * @returns Promise<AxiosResponse>
     */
    async loginWithGoogle(googleId: string): Promise<AxiosResponse<UserShape>> {
        type GoogleLoginDataShape = {
            google_id: string;
            coupon_code?: string;
        };
        let data: GoogleLoginDataShape = {
            google_id: googleId,
        };
        data = checkAddCoupon(data) as GoogleLoginDataShape;

        return Api.post('login/google', data);
    },

    /**
     * Gets user by Facebook id if it exists
     * @param {string} facebookId
     * @returns {Promise<AxiosResponse>}
     */
    async getByFacebookId(
        facebookId: string,
    ): Promise<AxiosResponse<UserShape>> {
        type FacebookGetDataShape = {
            facebookId: string;
            coupon_code?: string;
        };
        let data: FacebookGetDataShape = {
            facebookId: facebookId,
        };
        data = checkAddCoupon(data) as FacebookGetDataShape;

        let getQuery = 'facebook_id=' + data.facebookId;
        if (data.coupon_code) {
            getQuery += '&coupon_code=' + data.coupon_code;
        }

        return Api.get('get-facebook-user?' + getQuery);
    },

    /**
     * Gets user by Google id if it exists
     * @param {string} getByGoogleId
     * @returns {Promise<AxiosResponse>}
     */
    async getByGoogleId(googleId: string): Promise<AxiosResponse<UserShape>> {
        type GoogleGetDataShape = {
            googleId: string;
            coupon_code?: string;
        };
        let data: GoogleGetDataShape = {
            googleId: googleId,
        };
        data = checkAddCoupon(data) as GoogleGetDataShape;

        let getQuery = 'google_id=' + data.googleId;
        if (data.coupon_code) {
            getQuery += '&coupon_code=' + data.coupon_code;
        }

        return Api.get('get-google-user?' + getQuery);
    },

    /**
     * Triggers sending password reset for the given email
     * @param {ForgotPasswordData} data
     * @returns {Promise<AxiosResponse>}
     */
    async forgotPassword(
        data: ForgotPasswordData,
    ): Promise<AxiosResponse<{ success?: string }>> {
        try {
            const response = await Api.post('send-reset-password-email', data);
            return response;
        } catch (error) {
            console.error('Forgot password error. Error:', error);
            Sentry.captureException(error);
            throw error;
        }
    },

    /**
     * Gets referral count from the current user session
     *
     * @returns {Promise<AxiosResponse>}
     */
    async getReferralCount(): Promise<AxiosResponse<{ count: number }>> {
        return Api.get('referral-count');
    },

    /**
     * Resets password to the new one provided
     *
     * @param {ResetPasswordData} data
     * @returns  {Promise<AxiosResponse>}
     */
    async resetPassword(
        data: ResetPasswordData,
    ): Promise<AxiosResponse<{ success?: string; error?: string }>> {
        try {
            const response = await Api.post('reset-password', data);
            return response;
        } catch (error) {
            console.error('Reset password error. Error:', error);
            Sentry.captureException(error);
            throw error;
        }
    },

    /**
     * Gets data for the current user from the session
     * @returns  {Promise<AxiosResponse>}
     */
    async get(): Promise<AxiosResponse<UserShape>> {
        return Api.get('user');
    },

    /**
     * Logs out user
     * @returns  {Promise<AxiosResponse>}
     */
    logout(): Promise<AxiosResponse<void>> {
        return Api.post('logout');
    },
    /**
     * Update user data
     */
    async updateUser(
        data: UserUpdateDataShape,
    ): Promise<AxiosResponse<UserShape>> {
        return Api.post('user/update', data);
    },

    /**
     * Delete user
     *
     * @param {DeleteUserData} data
     * @returns  {Promise<AxiosResponse>}
     */
    async delete(data: DeleteUserData): Promise<AxiosResponse<void>> {
        return Api.post('user/delete', data);
    },

    /**
     * Update profile picture
     */
    async updateProfilePicture(
        data: FormData,
    ): Promise<AxiosResponse<UserShape>> {
        return ApiWithFileUpload.post('user/update', data);
    },

    /**
     *
     */
    async getUserByUsername(
        username: string,
    ): Promise<AxiosResponse<UserShape>> {
        return Api.get('user/get-by-username', { params: { username } });
    },
    /**
     * clear cookies
     */
    async clearCookies(): Promise<AxiosResponse<void>> {
        return Api.post('clear-cookies', []);
    },

    /**
     * Gets or refreshes Shopify access token
     * @returns {Promise<AxiosResponse>} Response containing Shopify access token data
     */
    async getShopifyAccessToken(): Promise<
        AxiosResponse<{
            access_token: string;
            expires_at: string;
            shopify_user_id: string;
        }>
    > {
        try {
            // First try to get the token from backend
            return await Api.get('shopify-storefront/access-token');
        } catch (error: unknown) {
            if ((error as AxiosError)?.response?.status === 401) {
                // Token expired, try to refresh
                try {
                    return await Api.post(
                        'shopify-storefront/access-token/refresh',
                    );
                } catch (refreshError) {
                    console.error(
                        'Error refreshing Shopify access token:',
                        refreshError,
                    );
                    throw refreshError;
                }
            }

            console.error('Error getting Shopify access token:', error);
            throw error;
        }
    },
};

export default UserApi;
