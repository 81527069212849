import * as Sentry from '@sentry/react';
import { ShopifyError, ShopifyErrorType } from '../types/errors';

interface GraphQLError extends Error {
    locations?: Array<{ line: number; column: number }>;
    extensions?: {
        code?: string;
    };
}

export interface ErrorContext {
    slice: string;
    endpoint: string;
    extra?: Record<string, unknown>;
}

export const handleShopifyError = (
    error: unknown,
    context: ErrorContext,
): { error: ShopifyError } => {
    const shopifyError = parseShopifyError(error);

    Sentry.withScope((scope) => {
        scope.setTags({
            component: 'ShopifyApi',
            slice: context.slice,
            endpoint: context.endpoint,
            errorType: shopifyError.type,
        });

        scope.setExtras({
            ...context.extra,
            errorMessage: shopifyError.message,
            errorCode: shopifyError.code,
            timestamp: new Date().toISOString(),
        });

        // TODO: We need to verify this comes in like we want
        Sentry.captureException(error);
    });

    return { error: shopifyError };
};

const parseShopifyError = (error: unknown): ShopifyError => {
    if (error instanceof Error) {
        // GraphQL errors
        if ('locations' in error) {
            const graphQLError = error as GraphQLError;
            return {
                type: ShopifyErrorType.GRAPHQL_ERROR,
                message: graphQLError.message,
                locations: graphQLError.locations,
                code: graphQLError.extensions?.code,
            };
        }

        // Network errors
        if (error.message === 'Network Error') {
            return {
                type: ShopifyErrorType.NETWORK_ERROR,
                message: 'Failed to connect to Shopify API',
            };
        }

        // Authentication errors
        if (
            error.message.includes('authentication') ||
            error.message.includes('unauthorized')
        ) {
            return {
                type: ShopifyErrorType.AUTHENTICATION_ERROR,
                message: 'Authentication failed with Shopify API',
            };
        }
    }

    return {
        type: ShopifyErrorType.UNKNOWN_ERROR,
        message: 'An unexpected error occurred',
    };
};
