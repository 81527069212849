import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import shopifyCartApi from '_services/Api/Shopify/shopifyCartApi';
import { RootState } from '_services/Redux/store';
import {
    selectCountryCode,
    selectShopifyAccessToken,
} from '_services/Redux/User/Selector/userSelectors';
import { RootReduxStateShape } from '_types/Redux';
import { CartStateShape } from './type';

/**
 * Redux Toolkit slice for managing Shopify cart state
 * Handles cart initialization, synchronization, and optimistic updates
 */

const STORE_CART_ID = 'cart_id';

const emptyCart = {
    id: '',
    checkoutUrl: '',
    lines: { nodes: [] },
    cost: {
        subtotalAmount: { amount: '0', currencyCode: 'NOK' },
        totalAmount: { amount: '0', currencyCode: 'NOK' },
    },
    discountCodes: [],
    discountAllocations: [],
    deliveryGroups: { nodes: [] },
};

const initialState: CartStateShape = {
    id: undefined,
    loading: false,
    error: null,
    initialized: false,
    contents: undefined,
    unsyncedChanges: {},
    requestCounter: 0,
};

/**
 * Initializes the cart by either:
 * 1. Retrieving and validating an existing cart from localStorage
 * 2. Creating a new cart if no valid cart exists
 *
 * @returns Cart data with ID and number of items, or null if initialization fails
 */
export const initaliseCart = createAsyncThunk(
    'cart/initialize',
    async (_, { getState, rejectWithValue }) => {
        try {
            const state = getState() as RootReduxStateShape;
            if (!state.user.data) {
                return rejectWithValue('No user data available');
            }

            if (state.shopifyCart.initialized) {
                return null;
            }

            const storedCartId = localStorage.getItem(STORE_CART_ID);

            if (storedCartId) {
                // First check if cart exists
                const validCart =
                    await shopifyCartApi.hasValidCart(storedCartId);
                if (validCart) {
                    // Then fetch full cart data
                    const fullCart = await shopifyCartApi.getCart(storedCartId);
                    if (fullCart) {
                        return {
                            id: storedCartId,
                            contents: fullCart,
                        };
                    }
                }
                localStorage.removeItem(STORE_CART_ID);
            }

            const accessToken = selectShopifyAccessToken(
                state as unknown as RootState,
            );
            if (!accessToken) {
                return rejectWithValue('No customer access token available');
            }
            const countryCode = selectCountryCode(
                state as unknown as RootState,
            );

            // Create new cart - no need to fetch full data as it will be empty
            const response = await shopifyCartApi.createCart(
                accessToken,
                countryCode ?? 'NO',
            );
            if (response?.cart.id) {
                localStorage.setItem(STORE_CART_ID, response.cart.id);
                return {
                    id: response.cart.id,
                    contents: {
                        ...emptyCart,
                        id: response.cart.id,
                    },
                };
            }
            return null;
        } catch (error) {
            return rejectWithValue(
                error instanceof Error
                    ? error.message
                    : 'Cart initialization failed',
            );
        }
    },
    {
        condition: (_, { getState }) => {
            const state = getState() as RootReduxStateShape;
            const cart = state.shopifyCart;
            if (cart.loading || cart.initialized || cart.id) {
                return false;
            }
            return true;
        },
    },
);

/**
 * Synchronizes cart line items with Shopify's server
 * Used after optimistic updates to ensure server state matches client state
 *
 * @param cartId - The Shopify cart ID
 * @param lines - Array of cart line items with quantities
 * @returns Updated cart data with new total number of items
 */
export const syncCartWithServer = createAsyncThunk(
    'cart/syncWithServer',
    async (
        {
            cartId,
            lines,
        }: {
            cartId: string;
            lines?: { id: string; quantity: number }[];
        },
        { rejectWithValue },
    ) => {
        try {
            // Update lines if provided
            if (lines?.length) {
                await shopifyCartApi.updateCartLines(cartId, lines);
            }

            // Always fetch full cart data
            const fullCart = await shopifyCartApi.getCart(cartId);
            if (!fullCart) {
                throw new Error('Cart is no longer valid');
            }

            return {
                cart: fullCart,
            };
        } catch (error) {
            return rejectWithValue(
                error instanceof Error ? error.message : 'Failed to sync cart',
            );
        }
    },
);

/**
 * Adds items to the cart
 */
export const addToCart = createAsyncThunk(
    'cart/addToCart',
    async (
        {
            cartId,
            merchandiseId,
            quantity,
        }: {
            cartId: string;
            merchandiseId: string;
            quantity: number;
        },
        { rejectWithValue },
    ) => {
        try {
            const response = await shopifyCartApi.addLinesToCart(cartId, [
                { merchandiseId, quantity },
            ]);

            if (!response?.cart) {
                throw new Error('Failed to add item to cart');
            }

            // Immediately fetch full cart data to ensure we have complete data
            const fullCart = await shopifyCartApi.getCart(cartId);
            if (!fullCart) {
                throw new Error('Failed to get updated cart');
            }

            return {
                cart: fullCart,
            };
        } catch (error) {
            return rejectWithValue(
                error instanceof Error
                    ? error.message
                    : 'Failed to add to cart',
            );
        }
    },
);

/**
 * Updates the quantity of a cart line item with server sync
 */
export const updateCartLineQuantity = createAsyncThunk(
    'cart/updateLineQuantity',
    async (
        {
            cartId,
            pendingChanges,
            requestNumber,
        }: {
            cartId: string;
            pendingChanges: { id: string; quantity: number }[];
            requestNumber: number;
        },
        { rejectWithValue },
    ) => {
        try {
            // Update all pending line quantities
            await shopifyCartApi.updateCartLines(cartId, pendingChanges);

            // Fetch the updated cart
            const fullCart = await shopifyCartApi.getCart(cartId);
            if (!fullCart) {
                throw new Error('Cart is no longer valid');
            }

            return {
                cart: fullCart,
                requestNumber,
                syncedLineIds: pendingChanges.map((change) => change.id),
            };
        } catch (error) {
            return rejectWithValue(
                error instanceof Error
                    ? error.message
                    : 'Failed to update quantity',
            );
        }
    },
);

/**
 * Adds a discount code to the cart
 *
 * @param cartId - The Shopify cart ID
 * @param code - The discount code to apply
 * @returns Updated cart data with applied discount
 */
export const addDiscountCode = createAsyncThunk(
    'cart/addDiscountCode',
    async (
        {
            cartId,
            code,
        }: {
            cartId: string;
            code: string;
        },
        { rejectWithValue },
    ) => {
        try {
            // Add code and fetch full cart data
            const fullCart = await shopifyCartApi.addDiscountCode(cartId, code);

            if (!fullCart) {
                throw new Error('Cart is no longer valid');
            }

            return {
                cart: fullCart,
            };
        } catch (error) {
            return rejectWithValue(
                error instanceof Error ? error.message : 'Failed to sync cart',
            );
        }
    },
);

/**
 * Cart slice containing reducers for:
 * - Setting cart ID
 * - Updating number of items
 * - Handling optimistic updates
 * - Managing async operations (initialization, syncing)
 *
 * Race Condition Handling:
 * - Uses a requestCounter to track changes and requests
 * - Counter is incremented on each change to invalidate stale responses
 * - Only responses matching current counter are applied
 * - This ensures changes are never lost even with multiple in-flight requests
 */
export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCartId: (
            state,
            action: PayloadAction<Pick<CartStateShape, 'id'>>,
        ) => {
            state.id = action.payload.id;
        },
        /**
         * Handles optimistic updates for line quantity changes
         * Used for both quantity updates and removals (quantity = 0)
         *
         * Race Condition Protection:
         * - Increments requestCounter for each change
         * - This ensures any in-flight responses become stale
         * - New changes are preserved until their request completes
         */
        updateLineItemOptimistically: (
            state,
            action: PayloadAction<{ lineId: string; quantity: number }>,
        ) => {
            if (!state.contents?.lines?.nodes) return;

            const lineItem = state.contents.lines.nodes.find(
                (item) => item.id === action.payload.lineId,
            );
            if (!lineItem) return;

            // Update the line item quantity
            state.contents.lines.nodes = state.contents.lines.nodes.map(
                (item) =>
                    item.id === action.payload.lineId
                        ? {
                              ...item,
                              quantity: action.payload.quantity,
                          }
                        : item,
            );

            // Track unsynced change and increment counter to invalidate stale responses
            state.unsyncedChanges[action.payload.lineId] =
                action.payload.quantity;
            state.requestCounter += 1;

            // Force a re-render
            state.contents = { ...state.contents };
        },
    },
    extraReducers: (builder) => {
        builder
            // Initialize cart
            .addCase(initaliseCart.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(initaliseCart.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    state.id = action.payload.id;
                    state.contents = action.payload.contents;
                }
                state.initialized = true;
            })
            .addCase(initaliseCart.rejected, (state, action) => {
                state.loading = false;
                state.error =
                    action.error.message || 'Cart initialization failed';
                console.error('Cart initialization failed');
            })
            // Update line quantity
            .addCase(updateCartLineQuantity.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCartLineQuantity.fulfilled, (state, action) => {
                state.loading = false;
                if ('cart' in action.payload) {
                    // Only apply updates if this is the latest request (matches current counter)
                    // This prevents applying stale responses when new changes exist
                    if (action.payload.requestNumber === state.requestCounter) {
                        state.contents = action.payload.cart;
                        state.unsyncedChanges = {}; // Clear all pending changes
                    }
                }
                state.error = null;
            })
            .addCase(updateCartLineQuantity.rejected, (state, action) => {
                state.loading = false;
                state.error =
                    action.error.message ?? 'Failed to update quantity';
            })
            // Add to cart
            .addCase(addToCart.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addToCart.fulfilled, (state, action) => {
                state.loading = false;
                state.contents = action.payload.cart;
                state.error = null;
            })
            .addCase(addToCart.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Failed to add to cart';
            })

            // add discount code
            .addCase(addDiscountCode.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addDiscountCode.fulfilled, (state, action) => {
                state.loading = false;
                state.contents = action.payload.cart;
                state.error = null;
            })
            .addCase(addDiscountCode.rejected, (state, action) => {
                state.loading = false;
                state.error =
                    action.error.message ?? 'Failed to add discount code';
            });
    },
});

export const { setCartId, updateLineItemOptimistically } = cartSlice.actions;

export default cartSlice.reducer;
