import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import BackIcon from '_assets/img/icons/back-icon.svg';
import CloseIcon from '_atoms/Icons/Close';
import Image from '_atoms/Image';
import BodyOverflow from 'Helper/BodyOverflow';
import IosStatusBarClass from 'Helper/IosStatusBarClass';
import UADetector from 'Helper/UADetector';
import { t } from 'i18next';
import { useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import './Modal.scss';

type contentStyleShape = {
    height?: string;
};

export interface ModalProps {
    title?: string;
    onClose: () => void;
    opened: boolean;
    children: ReactNode;
    extraClasses?: string;
    height?: string;
    animateFrom?:
        | 'none'
        | 'top'
        | 'bottom'
        | 'left'
        | 'right'
        | 'bottom-menu-dropup'
        | 'top-25'
        | 'top-120px'
        | 'download-pwa-annimation'
        | 'bottom-menu-dropup-300px'
        | 'bottom-menu-dropup-120px'
        | 'bottom-menu-dropup-422px';
    hasCloseIcon?: boolean;
    hasAutoHeight?: boolean;
    isClosingOnBackgroundClick?: boolean;
    hasAutoScrollToTop?: boolean;
    hasCloseOnSwipe?: boolean;
    isFullWidth?: boolean;
    hasBackButton?: boolean;
    animateOnClose?: boolean;
    goBack?: () => void;
    crossIcon?: string;
}

const Modal: FC<ModalProps> = ({
    title,
    onClose,
    opened,
    extraClasses,
    children,
    height,
    animateFrom = 'slide-up-300px',
    hasCloseIcon = true,
    hasAutoHeight = true,
    isClosingOnBackgroundClick = true,
    hasAutoScrollToTop = true,
    hasCloseOnSwipe = true,
    isFullWidth = false,
    hasBackButton = false,
    animateOnClose = true,
    goBack,
    crossIcon,
}): JSX.Element => {
    const [animateStyle, setAnimationStyle] = useState(animateFrom);
    const { isAndroid } = UADetector();
    /**
     * Gets modal classes depending on current state of extraClasses, opened and hasAutoheight
     * @returns {string}
     */
    const getClasses = useCallback((): string => {
        /**
         * Holds the default classes for this modal
         */
        let thisClasses = 'modal';
        if (hasAutoHeight) {
            thisClasses += ' auto-height';
        }

        if (opened) {
            thisClasses += ' opened';
        } else {
            thisClasses.replaceAll('opened', '');
        }

        if (isFullWidth) {
            thisClasses += ' full-width-modal';
        }

        if (extraClasses) {
            thisClasses += ' ' + extraClasses;
        }

        return thisClasses;
    }, [extraClasses, hasAutoHeight, opened]);
    /**
     * Stateful modal classes, allows for updating of the classes
     */
    const [classes, setClasses] = useState(getClasses());

    /**
     * Cleanup effect.
     * Cleanup happens when a function is returned within useEffect
     * Currenly only used to unHide body overflow, as it gets hidden when the modal is opened.
     */
    useEffect(
        () => () => {
            BodyOverflow.unHide();
        },
        [],
    );

    /**
     * Effect on changing opened. Adds or removes 'opened' class
     */
    useEffect(() => {
        if (opened) {
            BodyOverflow.hide(hasAutoScrollToTop);
            IosStatusBarClass.add('modal-open');
        } else {
            BodyOverflow.unHide();
            IosStatusBarClass.remove('modal-open');
        }
        setClasses(getClasses());
        return () => IosStatusBarClass.remove('modal-open');
    }, [getClasses, opened]);

    useEffect(() => {
        if (extraClasses?.length) {
            setClasses(getClasses());
        }
    }, [extraClasses, getClasses]);

    const ref = useRef<null | HTMLDivElement>(null);

    const closeAnnimation = useCallback(
        (type) => {
            /**
             * Closing animations
             */
            setAnimationStyle(type);
            // giving time to close
            setTimeout(() => {
                onClose();
            }, 300);
        },
        [onClose, setAnimationStyle],
    );

    /**
     * Closes the modal and calls the parent classModal handler
     */
    const close = useCallback(() => {
        setClasses(getClasses());
        BodyOverflow.unHide();
        IosStatusBarClass.remove('modal-open');
        if (typeof onClose === 'function') {
            if (animateOnClose) {
                if (animateStyle === 'right') {
                    closeAnnimation('left');
                    return;
                }
                if (animateStyle === 'slide-up-300px') {
                    closeAnnimation('slide-down-300px');
                    return;
                }
            }

            onClose();
        }
    }, [onClose, getClasses, animateStyle]);

    /**
     * Effect for preventing going back one page
     * TODO: Check if this is still needed.
     * TODO: Check why it always fires, not only when the modal is opened
     */
    useEffect(() => {
        const preventBack = (e: TouchEvent): void => {
            // is not near edge of view, exit
            if (
                e.touches[0].pageX > 20 &&
                e.touches[0].pageX < window.innerWidth - 20
            )
                return;

            // prevent swipe to navigate back gesture
            e.stopImmediatePropagation();
        };

        document.addEventListener('touchstart', preventBack, {
            passive: true,
        });

        return () => document.removeEventListener('touchstart', preventBack);
    }, [isAndroid]);

    const swipeHandlers = useSwipeable({
        onSwipedRight: (eventData) => {
            if (!hasCloseOnSwipe) {
                return;
            }

            /**
             * Event has property name initial
             * which is an array of current x,y position as
             * array like [0, 20], And we will take 0th value
             * from initial to get screen x position
             */
            const screenXPosition = eventData.initial[0];
            if (animateFrom === 'right' && screenXPosition <= 20) {
                close();
            }
        },
    });
    /**
     * Effect that sets listner for handling click outside the modal
     */
    useEffect(() => {
        if (isClosingOnBackgroundClick === false) {
            /**
             * If backgropClickable is false,
             * then we prevent the rest of the logic from happening
             */
            return;
        }

        /**
         * Hides the modal when clicking outside of it
         */
        const handleClickOutside = (event: MouseEvent): void => {
            const target = event.target as HTMLDivElement;
            if (ref.current && ref.current.contains(target) !== true) {
                close();
            }
        };
        if (opened === true) {
            setTimeout(
                () =>
                    document.addEventListener('click', handleClickOutside, {
                        capture: true,
                    }),
                10,
            );
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isClosingOnBackgroundClick, close, opened]);

    const contentStyle: contentStyleShape = {};
    /**
     * Converts the height number to vh
     * Or defaults to 90vh
     */
    if (height) {
        if (height.includes('px')) {
            contentStyle.height = height;
        } else {
            contentStyle.height = height + 'vh';
        }
    }

    let contentClasses = 'modal-content';
    if (animateStyle) {
        contentClasses += ' animate-' + animateStyle;
    }

    const closeButtonOnlyClass =
        !hasBackButton && !title && hasCloseIcon ? 'only-close-button' : '';

    return (
        <div className={classes} {...swipeHandlers}>
            <div
                id="modal-content"
                className={contentClasses}
                style={contentStyle}
                ref={ref}
            >
                <div className="modal-body">
                    {(!!title || hasBackButton || hasCloseIcon) && (
                        <div
                            className={`main-page-header ${closeButtonOnlyClass}`}
                        >
                            {hasBackButton && (
                                <div
                                    className={`back-button layout-back-button `}
                                    onClick={goBack ?? close}
                                >
                                    <Image alt={t('Go back')} src={BackIcon} />
                                </div>
                            )}
                            {!!title && <h2 className="title">{title}</h2>}
                            {hasCloseIcon && (
                                <div className="top-right-more-options">
                                    <CloseIcon
                                        src={crossIcon}
                                        onClick={() => close()}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
