import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { unauthorisedStatusCodes } from '_constants/statusCodes';
import User from '_services/Api/User';
import {
    clearCookiesAndSetLoggedOut,
    setIsLoggedIn,
} from '_services/LocalStorage/LoggedIn';
import { UserShape } from '_types/User';
import { ShopifyToken, UserStateShape } from './type';
import UserApi from '_services/Api/User';

global.isFetchingShopifyToken = false;

export const getUser = createAsyncThunk('user/getUser', async () => {
    try {
        const response = await User.get();
        const user = response.data ?? null;
        if (user) {
            setUserInSentry(user);
            return response.data;
        } else {
            clearCookiesAndSetLoggedOut();
            console.error(
                'Failure on retrieving user data. Response is..:',
                response,
            );
            return undefined;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        const statusCode = error.response?.status;
        if (unauthorisedStatusCodes.includes(statusCode)) {
            clearCookiesAndSetLoggedOut();
            removeUserInStentry();
            return undefined;
        } else {
            console.error('error when getting user is..', error);
        }
    }
});

export const logout = createAsyncThunk('user/logout', async () => {
    try {
        await User.logout();
        clearCookiesAndSetLoggedOut();
        /**
         * For google user sign-out
         */
        if (window.google?.accounts) {
            window.google.accounts.id.disableAutoSelect();
        }
        removeUserInStentry();
        window.location.href = '/login';
        return undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        if (error.response.status === 401) {
            clearCookiesAndSetLoggedOut();
            removeUserInStentry();
            window.location.href = '/login';
            return undefined;
        }
    }
});

export const fetchShopifyToken = createAsyncThunk(
    'user/fetchShopifyToken',
    async (_, { rejectWithValue }) => {
        try {
            const response = await UserApi.getShopifyAccessToken();
            const token = response.data;
            if (!token) {
                throw new Error('Failed to get Shopify access token');
            }
            return token;
        } catch {
            global.isFetchingShopifyToken = false;
            return rejectWithValue('Failed to fetch Shopify access token');
        }
    },
    {
        condition: () => {
            // If already fetching, prevent new attempts
            if (global.isFetchingShopifyToken) {
                return false;
            }
            global.isFetchingShopifyToken = true;
            return true;
        },
    },
);

export const setUserInSentry = (user: UserShape): void => {
    Sentry.setUser({ id: user.id.toString(), email: user.email });
};

export const removeUserInStentry = (): void => {
    Sentry.getCurrentScope().setUser(null);
};

const initialState: UserStateShape = {
    data: undefined,
    shopifyUserToken: undefined,
};

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        loginUser: (state, action: PayloadAction<UserShape>) => {
            setUserInSentry(action.payload);
            setIsLoggedIn();
            state.data = action.payload;
        },
        setUser: (state, action: PayloadAction<UserShape>) => {
            setUserInSentry(action.payload);
            state.data = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getUser.pending, () => {
                global.isFetchingUser = true;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.data = action.payload;
                global.isFetchingUser = false;
            })
            .addCase(getUser.rejected, () => {
                global.isFetchingUser = false;
            })
            .addCase(fetchShopifyToken.fulfilled, (state, action) => {
                state.shopifyUserToken = action.payload as ShopifyToken;
                global.isFetchingShopifyToken = false;
            })
            .addCase(fetchShopifyToken.rejected, (state) => {
                state.shopifyUserToken = undefined;
                global.isFetchingShopifyToken = false;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.data = action.payload;
                state.shopifyUserToken = undefined;
            });
    },
});

const AuthReducer = userSlice.reducer;

export const { setUser } = userSlice.actions;

export default AuthReducer;
