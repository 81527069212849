import Api from '_services/Api/Api';
import { gql } from 'graphql-request';
import { client } from './shopifyClient';

/**
 * Fragment for basic cart data
 * Used for lightweight operations like cart creation and validation
 */
const miniCartQuery = gql`
    {
        id
        lines(first: 10) {
            nodes {
                id
                quantity
                merchandise {
                    ... on ProductVariant {
                        quantityAvailable
                    }
                }
            }
        }
    }
`;

export interface MiniCartResponse {
    cart: {
        id: string;
        lines: { nodes: { quantity: number; merchandiseId: string }[] };
    };
    userErrors: {
        field: string[];
        message: string;
    }[];
}

/**
 * Complete cart query fragment
 * Used for operations requiring full cart details including:
 * - Cart identification (id, checkoutUrl)
 * - Discounts and their allocations
 * - Cost calculations (subtotal, total)
 * - Line items with full product details
 * - Delivery options and costs
 * - Custom meta fields (cutline_details)
 */
export const fullCartQuery = gql`
    {
        id
        checkoutUrl
        discountCodes {
            applicable
            code
        }
        discountAllocations {
            discountedAmount {
                amount
                currencyCode
            }
            targetType
            ... on CartCodeDiscountAllocation {
                code
                targetType
                discountedAmount {
                    amount
                    currencyCode
                }
            }
        }
        cost {
            subtotalAmount {
                amount
                currencyCode
            }
            totalAmount {
                amount
                currencyCode
            }
        }
        lines(first: 30) {
            nodes {
                id
                merchandise {
                    ... on ProductVariant {
                        id
                        image {
                            url
                        }
                        title
                        price {
                            amount
                            currencyCode
                        }
                        product {
                            id
                            title
                            productType
                            cutline_details: metafield(
                                namespace: "custom"
                                key: "cutline_details"
                            ) {
                                value
                            }
                            options {
                                name
                            }
                        }
                        quantityAvailable
                    }
                }
                quantity
                discountAllocations {
                    discountedAmount {
                        amount
                        currencyCode
                    }
                }
            }
        }
        deliveryGroups(first: 5) {
            nodes {
                id
                deliveryOptions {
                    estimatedCost {
                        amount
                        currencyCode
                    }
                    deliveryMethodType
                    code
                    title
                }
            }
        }
    }
`;

/**
 * Full cart response interface matching Shopify's cart query structure
 * Used across cart-related components and API calls
 * @see fullCartQuery in shopifyCartApi.ts for GraphQL query structure
 */
export interface FullCartResponse {
    id: string;
    checkoutUrl: string;
    discountCodes: {
        applicable: boolean;
        code: string;
    }[];
    discountAllocations: {
        discountedAmount: {
            amount: string;
            currencyCode: string;
        };
        targetType: string;
        code?: string;
    }[];
    cost: {
        subtotalAmount: {
            amount: string;
            currencyCode: string;
        };
        totalAmount: {
            amount: string;
            currencyCode: string;
        };
    };
    lines: {
        nodes: {
            id: string;
            merchandise: {
                id: string;
                title: string;
                image: {
                    url: string;
                };
                price: { amount: string; currencyCode: string };
                product: {
                    id: string;
                    title: string;
                    productType: string;
                    cutline_details: {
                        value: string;
                    };
                    options: {
                        name: string;
                    }[];
                };
                quantityAvailable: number;
            };
            quantity: number;
            discountAllocations: {
                discountedAmount: {
                    amount: string;
                    currencyCode: string;
                };
            }[];
        }[];
    };
    deliveryGroups: {
        nodes: {
            id: string;
            deliveryOptions: {
                estimatedCost: {
                    amount: string;
                    currencyCode: string;
                };
                deliveryMethodType: string;
                code: string;
                title: string;
            }[];
        }[];
    };
}

/**
 * Cart Operations:
 *
 * createCart: Creates new empty cart
 * addLinesToCart: Adds products to cart
 * removeLinesFromCart: Removes items from cart
 * updateCartLines: Updates quantities of existing items
 * addDiscountCode: Applies discount code to cart
 * getCart: Retrieves full cart details
 * hasValidCart: Validates cart existence
 *
 * Error Handling:
 * - All operations include GraphQL error logging
 * - Operations return undefined/false on failure
 * - Cart ID validation before operations
 *
 * Response Types:
 * - MiniCartResponse: Basic cart data (id, line items)
 * - FullCartResponse: Complete cart data structure
 */

// Cart creation with empty input
const createCart = async (
    customerAccessToken: string,
    countryCode: string,
): Promise<MiniCartResponse | undefined> => {
    const response = await Api.post(`shopify-storefront/cart/`, {
        customerAccessToken,
        countryCode,
    });

    try {
        // Transform the response data to match MiniCartResponse interface
        const cartData: MiniCartResponse = {
            cart: {
                id: response.data.id,
                lines: {
                    nodes:
                        response.data.lines.edges.map(
                            (edge: {
                                node: {
                                    quantity: number;
                                    merchandise: { id: string };
                                };
                            }) => ({
                                quantity: edge?.node?.quantity || 0,
                                merchandiseId:
                                    edge?.node?.merchandise?.id || '',
                            }),
                        ) || [],
                },
            },
            userErrors: [], // Initialize empty user errors array
        };

        return cartData;
    } catch (error) {
        console.error('Error transforming shopify cart response:', error);
        return undefined;
    }
};
export const getCheckoutUrl = async (
    customerAccessToken: string,
    cartId: string,
): Promise<{ checkoutUrl: string } | undefined> => {
    const response = await Api.post(`shopify-storefront/cart/checkout-url/`, {
        customerAccessToken,
        cartId,
    });
    return response.data;
};

// Add products to existing cart
const addLinesToCart = async (
    cartId: string,
    lines: { merchandiseId: string; quantity: number }[],
): Promise<{ cart: FullCartResponse } | undefined> => {
    const query = gql`
          mutation {
            cartLinesAdd(
                cartId: "${cartId}"
                lines: [
                  ${lines.map(
                      (l) =>
                          `     {
                            merchandiseId: "${l.merchandiseId}", 
                            quantity: ${l.quantity}
                          }`,
                  )}
                ]
              ) 
              {
                cart 
                  ${fullCartQuery}
              }
          }
    `;

    if (!cartId) {
        console.error('No cartId provided');
        return undefined;
    }

    try {
        const response = await client.request<{
            cartLinesAdd: { cart: FullCartResponse };
        }>(query);

        if (response.errors) {
            console.error(
                'Error adding lines to shopify cart:',
                response.errors,
            );
            return undefined;
        }

        return response.data?.cartLinesAdd;
    } catch (error) {
        console.error('Error adding lines to shopify cart:', error);
        return undefined;
    }
};

// Remove products from cart
const removeLinesFromCart = async (
    cartId: string,
    lineIds: string[],
): Promise<FullCartResponse | undefined> => {
    const query = gql`
    mutation {
      cartLinesRemove(
          cartId: "${cartId}"
          lineIds: [${lineIds.map((id) => `"${id}"`).join(', ')}]
          ) 
          {
          cart 
            ${fullCartQuery}
          }
        }
    `;
    let data: FullCartResponse | undefined;
    if (!cartId) {
        console.error('No cartId provided');
        return undefined;
    }
    try {
        const response = await client.request<{
            cartLinesRemove: { cart: FullCartResponse };
        }>(query);

        data = removeEmptyLines(response.data?.cartLinesRemove.cart);
        if (response.errors) {
            console.error(
                'Error removing lines from shopify cart:',
                response.errors.graphQLErrors?.map((e) => e.message),
            );
        }
    } catch (error) {
        console.error('Error removing lines from shopify cart:', error);
        return undefined;
    }

    return data;
};

// Update quantities of existing cart items
const updateCartLines = async (
    cartId: string,
    lineIds: { id: string; quantity: number }[],
): Promise<FullCartResponse | undefined> => {
    const query = gql`
    mutation {
        cartLinesUpdate(
          cartId: "${cartId}" 
          lines: [${lineIds
              .map((l) => `{id:"${l.id}", quantity:${l.quantity}}`)
              .join(', ')}]
        ) 
          {
          cart 
            ${fullCartQuery}
          }
        }
    `;
    let data: FullCartResponse | undefined;
    if (!cartId) {
        console.error('No cartId provided');
        return undefined;
    }
    try {
        const response = await client.request<{
            cartLinesUpdate: { cart: FullCartResponse };
        }>(query);

        data = removeEmptyLines(response.data?.cartLinesUpdate.cart);
        if (response.errors) {
            console.error(
                'Error updateCartLines to shopify cart:',
                response.errors.graphQLErrors?.map((e) => e.message),
            );
        }
    } catch (error) {
        console.error('Error updateCartLines to shopify cart:', error);
        return undefined;
    }

    return data;
};

// Apply discount code to cart
const addDiscountCode = async (
    cartId: string,
    discountCode: string,
): Promise<FullCartResponse | undefined> => {
    const query = gql`
     mutation {
        cartDiscountCodesUpdate(
            cartId: "${cartId}"
            discountCodes: ["${discountCode}"]
        )
          {
          cart 
            ${fullCartQuery}
          }
        }
    `;
    let data: FullCartResponse | undefined;
    if (!cartId) {
        console.error('No cartId provided');
        return;
    }
    try {
        const response = await client.request<{
            cartDiscountCodesUpdate: { cart: FullCartResponse };
        }>(query);

        data = removeEmptyLines(response.data?.cartDiscountCodesUpdate.cart);
        if (response.errors) {
            console.error(
                'Error addDiscountCode to shopify cart:',
                response.errors.graphQLErrors?.map((e) => e.message),
            );
        }
    } catch (error) {
        console.error('Error addDiscountCode to shopify cart:', error);
        return;
    }

    return data;
};

const removeEmptyLines = (
    data?: FullCartResponse,
): FullCartResponse | undefined => {
    if (data?.lines)
        data.lines.nodes = data?.lines.nodes.filter((line) => !!line.id);
    return data;
};

const getCart = async (
    cartId: string,
): Promise<FullCartResponse | undefined> => {
    const query = gql`
    query {
      cart(id: "${cartId}") 
      ${fullCartQuery}
    }
  `;

    let data: FullCartResponse | undefined;
    try {
        const response = await client.request<{
            cart: FullCartResponse;
        }>(query);
        data = removeEmptyLines(response.data?.cart);

        if (response.errors) {
            console.error(
                'Error fetching shopify cart:',
                response.errors.graphQLErrors,
            );
        }
    } catch (error) {
        console.error('Error fetching shopify cart:', error);
        return undefined;
    }

    return data;
};

// Validate cart existence and accessibility
const hasValidCart = async (
    cartId: string,
): Promise<MiniCartResponse | false> => {
    const query = gql`
    query {
      cart(id: "${cartId}")
      ${miniCartQuery}
    }
  `;

    let data: MiniCartResponse | undefined;
    try {
        const response = await client.request<MiniCartResponse>(query);
        data = response.data;

        if (response.errors) {
            console.error(
                'Error validating shopify cart:',
                response.errors.graphQLErrors,
            );
        }
    } catch (error) {
        console.error('Error validating shopify cart:', error);
        return false;
    }

    return data?.cart ? data : false;
};

export default {
    createCart,
    addLinesToCart,
    addDiscountCode,
    removeLinesFromCart,
    updateCartLines,
    getCart,
    hasValidCart,
};
