export enum ShopifyErrorType {
    GRAPHQL_ERROR = 'GRAPHQL_ERROR',
    NETWORK_ERROR = 'NETWORK_ERROR',
    AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
    RATE_LIMIT_ERROR = 'RATE_LIMIT_ERROR',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export interface ShopifyError {
    type: ShopifyErrorType;
    message: string;
    code?: string;
    locations?: Array<{ line: number; column: number }>;
}
