import { createApi } from '@reduxjs/toolkit/query/react';
import { client } from './shopifyClient';
import { handleShopifyError } from './utils/errorHandling';

/**
 * Shopify Base API slice using RTK Query
 * Provides:
 * - Base query configuration for Shopify GraphQL API
 * - Common error handling through handleShopifyError
 * - Shared cache invalidation tags across Shopify features
 * - Foundation for feature-specific API slices (cart, products, orders)
 *
 * Usage:
 * This base API is extended by other Shopify-specific slices:
 * - shopifyCartApi: Shopping cart operations
 * - shopifyProductApi: Product queries and mutations
 * - shopifyOrderApi: Order management
 * - shopifyMenuApi: Navigation and menu structure
 *
 * Pattern to follow when implementing feature slices:
 * ```typescript
 * // 1. Define GraphQL fragments for reuse
 * const CART_FIELDS = gql`
 *   fragment CartFields on Cart {
 *     id
 *     lines { nodes { id quantity } }
 *   }
 * `;
 *
 * // 2. Define response and input types
 * interface CartResponse {
 *   cart: { id: string };
 *   userErrors: Array<{ message: string }>;
 * }
 *
 * // 3. Implement the slice with proper error handling
 * export const shopifyCartApi = shopifyBaseApi.injectEndpoints({
 *   endpoints: (builder) => ({
 *     createCart: builder.mutation<CartResponse, CartInput>({
 *       query: (input) => ({
 *         query: gql`mutation { ... }`,
 *         variables: input
 *       }),
 *       // Handle Shopify-specific errors in transform
 *       transformResponse: (response) => {
 *         if (response.userErrors?.length > 0) {
 *           throw new Error(response.userErrors[0].message);
 *         }
 *         return response.cart;
 *       },
 *       // Specific error handling if needed
 *       async queryFn(args, api, extraOptions, baseQuery) {
 *         try {
 *           const result = await baseQuery({...});
 *           if (result.error) {
 *             return handleShopifyError(result.error, {
 *               slice: 'cart',        // Specific feature name
 *               endpoint: 'create',   // Specific operation
 *               extra: { args }
 *             });
 *           }
 *           return result;
 *         } catch (error) {
 *           return handleShopifyError(error, {...});
 *         }
 *       }
 *     })
 *   })
 * });
 * ```
 */

export const shopifyBaseApi = createApi({
    reducerPath: 'shopify',
    /**
     * Base query configuration for Shopify GraphQL API
     *
     * Flow:
     * 1. Accepts GraphQL query string and variables
     * 2. Makes request through GraphQL client
     * 3. Transforms successful response to RTK Query format: { data: result.data }
     * 4. Handles errors through handleShopifyError utility
     *
     * Note on error handling:
     * - slice: 'base' is used here as this is the base configuration
     * - endpoint: 'query' is generic as this handles both queries/mutations
     *
     * Feature slices should use specific values:
     * handleShopifyError(error, {
     *   slice: 'cart',          // The specific feature (cart, product, etc)
     *   endpoint: 'addToCart',  // The specific operation being performed
     *   extra: { ... }          // Relevant error context
     * })
     *
     * @param query - GraphQL query/mutation string
     * @param variables - Optional query variables
     * @returns Promise with either { data } or { error }
     */
    baseQuery: async ({
        query,
        variables,
    }: {
        query: string;
        variables?: Record<string, unknown>;
    }) => {
        try {
            const result = await client.request(query, {
                variables: variables,
            });
            return { data: result.data };
        } catch (error) {
            return handleShopifyError(error, {
                slice: 'base',
                endpoint: 'query',
                extra: { query, variables },
            });
        }
    },
    /**
     * Cache invalidation tags for different entity types
     *
     * Product - Product details, listings, and mutations
     *          Invalidated by: product updates, variant changes, inventory updates
     *          Used by: product queries, search results, collection listings
     *
     * Cart    - Shopping cart state and operations
     *          Invalidated by: line item changes, checkout, price updates
     *          Used by: cart queries, checkout process, cart mutations
     *
     * Order   - Customer order history and management
     *          Invalidated by: new orders, status changes, fulfillment updates
     *          Used by: order history, order details, tracking info
     *
     * Filter  - Product filtering and search parameters
     *          Invalidated by: product updates, collection changes, taxonomy updates
     *          Used by: search filters, collection filters, product type filters
     *
     * Review  - Customer product reviews and ratings
     *          Invalidated by: new reviews, review updates, moderation changes
     *          Used by: product reviews, rating summaries, review management
     *
     * Note: When implementing mutations, ensure proper tag invalidation
     * to maintain cache consistency across the application.
     */
    tagTypes: ['Product', 'Cart', 'Order', 'Filter', 'Review', 'Collection'],
    endpoints: () => ({}),
});
