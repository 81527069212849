import type { RootState } from '../../store';
import { ShopifyToken } from '../type';

export const selectShopifyUserToken = (
    state: RootState,
): ShopifyToken | undefined => {
    return state.user.shopifyUserToken;
};

export const selectShopifyAccessToken = (
    state: RootState,
): string | undefined => {
    return state.user.shopifyUserToken?.access_token;
};

export const selectCountryCode = (state: RootState): string | undefined => {
    return state.user.data?.country?.code;
};

export const selectShopifyUserId = (state: RootState): string | undefined => {
    return state.user.shopifyUserToken?.shopify_user_id;
};

export const selectShopifyTokenExpiresAt = (
    state: RootState,
): string | undefined => {
    return state.user.shopifyUserToken?.expires_at;
};
