import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ShopifyPage = lazy(() => import('Pages/Protected/Shopify'));
const ProductDetails = lazy(
    () => import('Components/Shopify/ProductDetails/ProductDetails'),
);
const ShopifyPageContent = lazy(
    () => import('Components/Shopify/ShopifyPageContent/ShopifyPageContent'),
);
const CartContents = lazy(() => import('Components/Shopify/Cart/CartContents'));
const SearchPage = lazy(() => import('Components/Shopify/Explore'));
const Collections = lazy(
    () => import('Components/Shopify/Collections/Collections'),
);
const ProductList = lazy(
    () => import('Components/Shopify/ProductList/ProductList'),
);
const MyOrders = lazy(() => import('Components/Shopify/MyOrders/MyOrders'));
const ShopifyRedirect = lazy(
    () => import('Components/Shopify/ShopifyRedirect'),
);
const OrderDetails = lazy(
    () => import('Components/Shopify/MyOrders/OrderDetails'),
);

const shopifyRoutes: RouteObject[] = [
    {
        path: 'shop/',
        children: [
            {
                path: '',
                element: <ShopifyPage />,
            },
            {
                path: 'product/:handle',
                element: <ProductDetails />,
            },
            {
                path: 'page/:handle',
                element: <ShopifyPageContent />,
            },
            {
                path: 'cart',
                element: <CartContents />,
            },
            {
                path: 'search',
                element: <SearchPage />,
            },
            {
                path: 'yarn',
                element: <Collections type="yarn" />,
            },
            {
                path: 'needles',
                element: <Collections type="needles" />,
            },
            {
                path: 'accessories',
                element: <Collections type="accessories" />,
            },
            {
                path: 'productlist/:handle/',
                element: <ProductList />,
            },
            {
                path: 'my-orders/',
                element: <MyOrders />,
            },
            {
                path: 'my-orders/order/:ordergid',
                element: <OrderDetails />,
            },
        ],
    },
    {
        path: 'shopify-redirect/*',
        element: <ShopifyRedirect />,
    },
];

export default shopifyRoutes;
